// src/layouts/MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../components';
import Footer from '../components/Footer';

const MainLayout = () => {
  return (
    <>
      <Header />
      <main className="main-content">
        <Outlet /> {/* Renders the matched child route */}
      </main>
      <Footer />
    </>
  );
};

export default MainLayout;
