// src/pages/DashboardPage/Dashboard/Integrations/IntegrationsPage.js

import React, { useState } from 'react';
import { FaSlack, FaGithub, FaGoogle, FaTrello } from 'react-icons/fa';
import styles from './IntegrationsPage.module.css';

const IntegrationsPage = () => {
  const [integrations, setIntegrations] = useState([
    { id: 1, name: 'Slack', connected: true, icon: FaSlack },
    { id: 2, name: 'GitHub', connected: false, icon: FaGithub },
    { id: 3, name: 'Google Drive', connected: true, icon: FaGoogle },
    { id: 4, name: 'Trello', connected: false, icon: FaTrello },
  ]);

  const toggleConnection = (id) => {
    setIntegrations(integrations.map(integration =>
      integration.id === id ? { ...integration, connected: !integration.connected } : integration
    ));
  };

  return (
    <div className={styles.integrationsPage}>
      <h1 className={styles.pageTitle}>Integrations</h1>
      
      <div className={styles.integrationGrid}>
        {integrations.map((integration) => (
          <div key={integration.id} className={styles.integrationCard}>
            <div className={styles.integrationIcon}>
              {React.createElement(integration.icon, { size: 48 })}
            </div>
            <h3 className={styles.integrationName}>{integration.name}</h3>
            <button
              className={`${styles.connectionButton} ${integration.connected ? styles.connected : styles.disconnected}`}
              onClick={() => toggleConnection(integration.id)}
            >
              {integration.connected ? 'Connected' : 'Connect'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationsPage;
