import React, { useState, useContext } from 'react';
import { FaCog, FaShieldAlt, FaDatabase, FaBell, FaPalette, FaGlobe, FaKey, FaSignOutAlt, FaArchive, FaTrash } from 'react-icons/fa';
import styles from './SettingsPage.module.css';
import ConfirmationModal from '../../../../components/common/Modal/ConfirmationModal';
import NotificationModal from '../../../../components/common/Modal/NotificationModal';
import { MessageContext } from '../../../../contexts/MessageContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import axiosInstance from '../../../../utils/axiosInstance';

const SettingsPage = () => {
  const { user } = useContext(AuthContext);
  const { fetchConversations } = useContext(MessageContext);
  const [activeTab, setActiveTab] = useState('general');
  const [notifications, setNotifications] = useState(true);
  const [theme, setTheme] = useState('dark');
  const [language, setLanguage] = useState('english');
  
  // Loading States
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSignOut, setLoadingSignOut] = useState(false);
  
  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [signOutModalOpen, setSignOutModalOpen] = useState(false);
  const [notificationModal, setNotificationModal] = useState({
    isOpen: false,
    type: 'success',
    title: '',
    message: ''
  });

  const showNotification = (type, title, message) => {
    setNotificationModal({
      isOpen: true,
      type,
      title,
      message
    });
  };

  const handleSignOutAllDevices = async () => {
    setLoadingSignOut(true);
    try {
      const response = await axiosInstance.post('/auth/signout-all');
      if (response.data.status === 'success') {
        showNotification('success', 'Success', 'Successfully signed out of all devices');
      } else {
        showNotification('error', 'Error', 'Failed to sign out of all devices');
      }
    } catch (error) {
      console.error('Error signing out:', error);
      showNotification('error', 'Error', 'An error occurred while signing out of devices');
    } finally {
      setLoadingSignOut(false);
      setSignOutModalOpen(false);
    }
  };

  const handleDeleteAllConversations = async () => {
    setLoadingDelete(true);
    try {
      const response = await axiosInstance.delete('/conversations/deleteAll');
      if (response.data.status === 'success') {
        await fetchConversations(); // Fetch fresh conversation list
        showNotification('success', 'Success', 'All chats have been deleted successfully');
      } else {
        showNotification('error', 'Error', 'Failed to delete all chats');
      }
    } catch (error) {
      console.error('Error deleting all chats:', error);
      showNotification('error', 'Error', 'An error occurred while deleting chats');
    } finally {
      setLoadingDelete(false);
      setDeleteModalOpen(false);
    }
  };

  const handleArchiveAll = async () => {
    setLoadingArchive(true);
    try {
      const response = await axiosInstance.post('/conversations/archiveAll');
      if (response.data.status === 'success') {
        await fetchConversations(); // Fetch fresh conversation list
        showNotification('success', 'Success', 'All chats have been archived successfully');
      } else {
        showNotification('error', 'Error', 'Failed to archive all chats');
      }
    } catch (error) {
      console.error('Error archiving all chats:', error);
      showNotification('error', 'Error', 'An error occurred while archiving chats');
    } finally {
      setLoadingArchive(false);
      setArchiveModalOpen(false);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'general':
        return (
          <div className={styles.settingSection}>
            <div className={styles.sectionHeader}>
              <FaCog className={styles.sectionIcon} />
              <h2>General Settings</h2>
            </div>
            <div className={styles.settingGroup}>
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaBell className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Notifications</h3>
                    <p>Receive updates about your conversations</p>
                  </div>
                </div>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={notifications}
                    onChange={() => setNotifications(!notifications)}
                  />
                  <span className={styles.slider}></span>
                </label>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaPalette className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Theme</h3>
                    <p>Choose your preferred appearance</p>
                  </div>
                </div>
                <select
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  className={styles.select}
                >
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                  <option value="system">System</option>
                </select>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaGlobe className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Language</h3>
                    <p>Select your preferred language</p>
                  </div>
                </div>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className={styles.select}
                >
                  <option value="english">English</option>
                  <option value="spanish">Espa�ol</option>
                  <option value="french">Fran�ais</option>
                </select>
              </div>
            </div>
          </div>
        );

      case 'security':
        return (
          <div className={styles.settingSection}>
            <div className={styles.sectionHeader}>
              <FaShieldAlt className={styles.sectionIcon} />
              <h2>Security Settings</h2>
            </div>
            <div className={styles.settingGroup}>
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaKey className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Two-Factor Authentication</h3>
                    <p>Add an extra layer of security to your account</p>
                  </div>
                </div>
                <button className={styles.actionButton}>Enable 2FA</button>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaSignOutAlt className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Active Sessions</h3>
                    <p>Manage your logged-in devices</p>
                  </div>
                </div>
                <button 
                  className={styles.actionButton}
                  onClick={() => setSignOutModalOpen(true)}
                  disabled={loadingSignOut}
                >
                  {loadingSignOut ? 'Signing Out...' : 'Sign Out All Devices'}
                </button>
              </div>
            </div>
          </div>
        );

      case 'data':
        return (
          <div className={styles.settingSection}>
            <div className={styles.sectionHeader}>
              <FaDatabase className={styles.sectionIcon} />
              <h2>Data Controls</h2>
            </div>
            <div className={styles.settingGroup}>
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaArchive className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Archive Conversations</h3>
                    <p>Move all conversations to archive</p>
                  </div>
                </div>
                <button
                  className={`${styles.actionButton} ${styles.archiveButton}`}
                  onClick={() => setArchiveModalOpen(true)}
                  disabled={loadingArchive}
                >
                  {loadingArchive ? 'Archiving...' : 'Archive All'}
                </button>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaTrash className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Delete All Data</h3>
                    <p>Permanently remove all conversations</p>
                  </div>
                </div>
                <button
                  className={`${styles.actionButton} ${styles.deleteButton}`}
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={loadingDelete}
                >
                  {loadingDelete ? 'Deleting...' : 'Delete All'}
                </button>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.settingsPage}>
      <aside className={styles.sidebar}>
        <h2 className={styles.sidebarTitle}>Settings</h2>
        <ul className={styles.sidebarNav}>
          <li
            className={`${styles.sidebarItem} ${activeTab === 'general' ? styles.active : ''}`}
            onClick={() => setActiveTab('general')}
          >
            <FaCog /> General
          </li>
          <li
            className={`${styles.sidebarItem} ${activeTab === 'security' ? styles.active : ''}`}
            onClick={() => setActiveTab('security')}
          >
            <FaShieldAlt /> Security
          </li>
          <li
            className={`${styles.sidebarItem} ${activeTab === 'data' ? styles.active : ''}`}
            onClick={() => setActiveTab('data')}
          >
            <FaDatabase /> Data Controls
          </li>
        </ul>
      </aside>
      <main className={styles.content}>
        {renderContent()}

        {/* Delete Confirmation Modal */}
        <ConfirmationModal
          isOpen={deleteModalOpen}
          title="Delete All Chats"
          message="Are you sure you want to delete all your chats? This action cannot be undone."
          onConfirm={handleDeleteAllConversations}
          onCancel={() => setDeleteModalOpen(false)}
          confirmText={loadingDelete ? 'Deleting...' : 'Delete All'}
          type="danger"
          isLoading={loadingDelete}
        />

        {/* Archive Confirmation Modal */}
        <ConfirmationModal
          isOpen={archiveModalOpen}
          title="Archive All Chats"
          message="Are you sure you want to archive all your chats?"
          onConfirm={handleArchiveAll}
          onCancel={() => setArchiveModalOpen(false)}
          confirmText={loadingArchive ? 'Archiving...' : 'Archive All'}
          type="warning"
          isLoading={loadingArchive}
        />

        {/* Sign Out Confirmation Modal */}
        <ConfirmationModal
          isOpen={signOutModalOpen}
          title="Sign Out All Devices"
          message="Are you sure you want to sign out of all devices? You will need to log in again on each device."
          onConfirm={handleSignOutAllDevices}
          onCancel={() => setSignOutModalOpen(false)}
          confirmText={loadingSignOut ? 'Signing Out...' : 'Sign Out All'}
          type="warning"
          isLoading={loadingSignOut}
        />

        {/* Notification Modal */}
        <NotificationModal
          isOpen={notificationModal.isOpen}
          type={notificationModal.type}
          title={notificationModal.title}
          message={notificationModal.message}
          onClose={() => setNotificationModal(prev => ({ ...prev, isOpen: false }))}
        />
      </main>
    </div>
  );
};

export default SettingsPage;