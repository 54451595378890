// src/components/common/Button/Button.js

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

const Button = ({ children, onClick, variant = 'primary', ...props }) => {
  return (
    <button
      className={`${styles.button} ${styles[`button-${variant}`]}`}
      onClick={onClick}
      {...props}
      aria-pressed={props['aria-pressed']}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'outline']), // Added 'outline' as an option
};

Button.defaultProps = {
  onClick: () => {}, // Providing a default no-op function
};

export default Button;
