// src/layouts/DashboardLayout.js

import React from 'react';
import { DashboardLayout as DashboardLayoutComponent } from '../pages/DashboardPage/Dashboard';

const DashboardLayout = () => {
  return <DashboardLayoutComponent />;
};

export default DashboardLayout;
