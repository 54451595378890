import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';
import styles from './NotificationModal.module.css';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const NotificationModal = ({ 
  isOpen, 
  type = 'success', 
  title, 
  message, 
  onClose 
}) => {
  const Icon = type === 'success' ? FaCheckCircle : FaTimesCircle;

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      size="small"
      actions={
        <button 
          className={`${styles.button} ${styles[type]}`}
          onClick={onClose}
        >
          Close
        </button>
      }
    >
      <div className={styles.content}>
        <Icon className={`${styles.icon} ${styles[type]}`} />
        <p className={styles.message}>{message}</p>
      </div>
    </Modal>
  );
};

NotificationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['success', 'error']),
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationModal;