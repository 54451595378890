// src/utils/axiosInstance.js

import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://synchrotronai.com/api',
  withCredentials: true, // Send cookies with requests
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      const requestUrl = error.config.url;

      // Avoid redirecting for auth-related endpoints
      if (!requestUrl.startsWith('/auth/')) {
        // Redirect to login
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
