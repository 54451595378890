// src/contexts/WebSocketContext.js

import React, { createContext, useContext, useRef, useState, useEffect } from 'react';
import { MessageContext } from './MessageContext';

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { addMessage, activeConversationId } = useContext(MessageContext);
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const MAX_RECONNECT_DELAY = 5000;
  const MAX_RETRIES = 3;
  const reconnectAttemptsRef = useRef(0);
  const messageQueueRef = useRef([]);

  const cleanup = () => {
    if (wsRef.current) {
      console.log('Cleaning up WebSocket connection');
      wsRef.current.onclose = null;
      wsRef.current.onerror = null;
      wsRef.current.onmessage = null;
      
      if (wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.close();
      }
      
      wsRef.current = null;
    }
    setIsConnected(false);
    
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
  };

  const handleWebSocketMessage = (event) => {
    try {
      const message = JSON.parse(event.data);
      console.log('Raw WebSocket message:', message);
      
      if (!message.type) return;
  
      // Construct the base message keeping task_status consistent
      const baseMessage = {
        id: message._id || `${wsRef.current.conversation_id}-msg-${Date.now()}`,
        conversation_id: wsRef.current.conversation_id,
        parent_id: message.parent_id,
        sender: message.type.startsWith('MESSAGE_TYPE_USER') ? 'user' : 'bot',
        text: message.content?.message_text || '',
        timestamp: message.timestamp || new Date().toISOString(),
        type: message.type,
        task_status: message.content?.task_status || null,  // Set from content
        content: message.content || {}  // Preserve entire content object
      };
  
      console.log('Processed message:', baseMessage);
      addMessage(wsRef.current.conversation_id, baseMessage);
    } catch (error) {
      console.error('Error handling message:', error);
    }
  };

  const connect = () => {
    if (!activeConversationId) {
      console.warn('No active conversation ID, cannot establish WebSocket connection.');
      return;
    }

    cleanup();

    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsUrl = `${protocol}//${window.location.host}/api/ws?conversation_id=${activeConversationId}`;
    console.log(`Establishing new WebSocket connection for conversation: ${activeConversationId}`);
    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;
    wsRef.current.conversation_id = activeConversationId;

    ws.onopen = () => {
      console.log('WebSocket connected');
      setIsConnected(true);
      reconnectAttemptsRef.current = 0;

      if (messageQueueRef.current.length > 0) {
        console.log(`Sending ${messageQueueRef.current.length} queued messages.`);
        messageQueueRef.current.forEach((msgContent) => {
          const message = {
            schema_version: "v1",
            timestamp: new Date().toISOString(),
            type: "MESSAGE_TYPE_USER_MESSAGE",
            content: {
              message_text: msgContent.message_text,
              task_status: "TASK_STATUS_PENDING"
            },
            parent_id: msgContent.parent_id
          };
          ws.send(JSON.stringify(message));
        });
        messageQueueRef.current = [];
      }
    };

    ws.onmessage = handleWebSocketMessage;

    ws.onclose = (event) => {
      console.log('WebSocket closed:', event.code, event.reason);
      setIsConnected(false);
      wsRef.current = null;

      if (reconnectAttemptsRef.current < MAX_RETRIES) {
        const delay = Math.min(1000 * Math.pow(2, reconnectAttemptsRef.current), MAX_RECONNECT_DELAY);
        console.log(`Attempting to reconnect in ${delay}ms...`);
        reconnectTimeoutRef.current = setTimeout(() => {
          reconnectAttemptsRef.current += 1;
          connect();
        }, delay);
      } else {
        console.error('Max WebSocket reconnection attempts reached.');
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      ws.close();
    };
  };

  useEffect(() => {
    if (activeConversationId) {
      connect();
    }

    return () => {
      cleanup();
    };
  }, [activeConversationId]);

  const sendMessage = (messageContent) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const message = {
        schema_version: "v1",
        timestamp: new Date().toISOString(),
        type: "MESSAGE_TYPE_USER_MESSAGE",
        content: {
          message_text: messageContent.message_text,
          task_status: "TASK_STATUS_PENDING"
        },
        parent_id: messageContent.parent_id
      };
      wsRef.current.send(JSON.stringify(message));
      return true;
    } else {
      console.warn('WebSocket is not connected. Queuing message.');
      messageQueueRef.current.push(messageContent);
      return false;
    }
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{
      isConnected,
      sendMessage,
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export default WebSocketProvider;
