// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useLocation } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const location = useLocation(); // Get current location

  const checkAuth = useCallback(async () => {
    console.log('Checking auth...');
    setLoading(true);
    try {
      const response = await axiosInstance.get('/auth/user');
      console.log('Auth response:', response.data);
      if (response.data.authenticated) {
        setAuthenticated(true);
        setUser(response.data.user);
      } else {
        setAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setAuthenticated(false);
        setUser(null);
      } else {
        console.error('Auth check error:', error);
        setAuthenticated(false);
        setUser(null);
        setError('Failed to authenticate.');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const refreshAuth = useCallback(async () => {
    try {
      const response = await axiosInstance.post('/auth/refresh');
      if (response.data.status === 'success') {
        setAuthenticated(true);
        if (response.data.user) {
          setUser(response.data.user);
        }
      } else {
        setAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error refreshing authentication:', error);
      setAuthenticated(false);
      setUser(null);
    }
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await axiosInstance.post('/auth/logout');
      setAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
      setAuthenticated(false);
      setUser(null);
    }
  }, []);

  const addConversation = useCallback(async () => {
    try {
      const response = await axiosInstance.post('/conversations');
      if (response.data.status === 'success') {
        const newConversation = response.data.conversation;
        return newConversation;
      } else {
        throw new Error('Failed to create conversation.');
      }
    } catch (error) {
      console.error('Error creating conversation:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    checkAuth();
    const intervalId = setInterval(() => {
      if (authenticated) {
        refreshAuth();
      }
    }, 15 * 60 * 1000); // Refresh every 15 minutes

    return () => clearInterval(intervalId);
  }, [checkAuth, refreshAuth, authenticated]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        loading,
        error,
        refreshAuth,
        handleLogout,
        addConversation,
        checkAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
