// MessageGroup.js

import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import PropTypes from 'prop-types';
import styles from './MessageGroup.module.css';
import classNames from 'classnames';
import TaskStatusDropdown from '../../pages/DashboardPage/Dashboard/TaskStatusDropdown/TaskStatusDropdown';

const MessageGroup = ({ messages }) => {
  const groupedMessages = useMemo(() => {
    const groups = {};
    
    messages.forEach((msg) => {
      if (msg.type === 'MESSAGE_TYPE_USER_MESSAGE') {
        groups[msg.id] = {
          user_message: msg,
          responses: [],
          final_result: null,
        };
      } else if (msg.type === 'MESSAGE_TYPE_RESPONSE_USER_MESSAGE' && msg.parent_id) {
        if (!groups[msg.parent_id]) {
          groups[msg.parent_id] = {
            responses: [],
            final_result: null,
          };
        }
        groups[msg.parent_id].responses.push(msg);
      } else if (msg.type === 'MESSAGE_TYPE_FINAL_RESULT' && msg.parent_id) {
        if (!groups[msg.parent_id]) {
          groups[msg.parent_id] = {
            responses: [],
            final_result: null,
          };
        }
        groups[msg.parent_id].final_result = msg;
      }
    });
    
    // Sort responses by timestamp if they exist
    Object.values(groups).forEach(group => {
      if (group.responses.length > 0) {
        group.responses.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      }
    });
    
    return Object.values(groups).filter(group => group.user_message || group.responses.length > 0);
  }, [messages]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="flex-1 overflow-y-auto px-4">
      <div className="max-w-3xl mx-auto">
        {groupedMessages.map((group, index) => (
          <div key={`message-group-${index}`} className={styles.messageWrapper}>
            {/* User Message */}
            {group.user_message && (
              <div className={classNames(styles.message, styles.messageUser)}>
                <div className={styles.messageText}>
                  <div>{group.user_message.text}</div>
                  <div className={styles.messageTimestamp}>
                    {formatTimestamp(group.user_message.timestamp)}
                  </div>
                </div>
              </div>
            )}

            {/* Task Status Dropdown */}
            {(group.responses.length > 0 || group.final_result) && (
              <TaskStatusDropdown 
                responses={group.responses} 
                finalResult={group.final_result} 
              />
            )}

            {/* Bot Response */}
            {group.final_result && (
              <div 
                className={classNames(styles.message, styles.messageBot)}
                role="region"
                aria-label="Assistant response"
              >
                <div className={styles.messageText}>
                  <div className={styles.markdownContent}>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeSanitize]}
                      components={{
                        p: ({ node, ...props }) => <p className={styles.paragraph} {...props} />,
                        code: ({ node, inline, ...props }) => (
                          <code className={inline ? styles.inlineCode : styles.codeBlock} {...props} />
                        ),
                      }}
                    >
                      {group.final_result.text}
                    </ReactMarkdown>
                  </div>
                  <div className={styles.messageTimestamp}>
                    {formatTimestamp(group.final_result.timestamp)}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

MessageGroup.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      text: PropTypes.string,
      content: PropTypes.shape({
        message_text: PropTypes.string
      }),
      timestamp: PropTypes.string.isRequired,
      parent_id: PropTypes.string,
    })
  ).isRequired,
};

MessageGroup.defaultProps = {
  messages: [],
};

export default MessageGroup;