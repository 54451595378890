// src/App.js

import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';

// Layout components
import MainLayout from './layouts/MainLayout';
import DashboardLayout from './layouts/DashboardLayout';
import ProtectedRoute from './contexts/ProtectedRoute';

// Context Providers
import { MessageProvider } from './contexts/MessageContext';
import { WebSocketProvider } from './contexts/WebSocketContext';

// StripeProvider is lazily loaded and wrapped around specific routes
const StripeProvider = lazy(() => import('./contexts/StripeContext'));

// Main pages (some are lazily loaded for optimization)
const HomePage = lazy(() => import('./pages/HomePage/HomePage'));
const ClientSignupLoginPage = lazy(() => import('./pages/ClientSignupLoginPage/ClientSignupLoginPage'));
const SignupPage = lazy(() => import('./pages/ClientSignupLoginPage/SignupPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage/NotFoundPage'));
const PricingPage = lazy(() => import('./pages/PricingPage/PricingPage'));

// Dashboard pages
const DashboardPage = lazy(() => import('./pages/DashboardPage/DashboardPage'));
const SettingsPage = lazy(() => import('./pages/DashboardPage/Dashboard/Settings/SettingsPage'));
const IntegrationsPage = lazy(() => import('./pages/DashboardPage/Dashboard/Integrations/IntegrationsPage'));

// Company pages
const AboutUsPage = lazy(() => import('./pages/company/AboutUsPage'));
const CareersPage = lazy(() => import('./pages/company/CareersPage'));
const CharterPage = lazy(() => import('./pages/company/CharterPage'));
const ResidencyPage = lazy(() => import('./pages/company/ResidencyPage'));
const SecurityPage = lazy(() => import('./pages/company/SecurityPage'));
const NewsPage = lazy(() => import('./pages/company/NewsPage'));

// API pages
const APIOverviewPage = lazy(() => import('./pages/api/APIOverviewPage'));
const APIDocumentationPage = lazy(() => import('./pages/api/APIDocumentationPage'));
const APIPricingPage = lazy(() => import('./pages/api/APIPricingPage'));

// Community pages
const EventsPage = lazy(() => import('./pages/community/EventsPage'));
const ForumsPage = lazy(() => import('./pages/community/ForumsPage'));
const AmbassadorsPage = lazy(() => import('./pages/community/AmbassadorsPage'));

// Developers pages
const SDKsPage = lazy(() => import('./pages/developers/SDKsPage'));
const CodeSamplesPage = lazy(() => import('./pages/developers/CodeSamplesPage'));
const APIIntegrationsPage = lazy(() => import('./pages/developers/APIIntegrationsPage'));

// Products pages
const ProductsPage = lazy(() => import('./pages/products/ProductsPage'));
const SoloPage = lazy(() => import('./pages/products/SoloPage'));
const SoloForAnyonePage = lazy(() => import('./pages/products/SoloForAnyonePage'));
const SoloForEnterprisePage = lazy(() => import('./pages/products/SoloForEnterprisePage'));

// Research pages
const ResearchPage = lazy(() => import('./pages/research/ResearchPage'));
const ResearchIndexPage = lazy(() => import('./pages/research/ResearchIndexPage'));
const AutonomousSystemsPage = lazy(() => import('./pages/research/AutonomousSystemsPage'));

// Terms & Policies pages
const TermsPage = lazy(() => import('./pages/terms/TermsPage'));
const PrivacyPage = lazy(() => import('./pages/terms/PrivacyPage'));
const BrandGuidelinesPage = lazy(() => import('./pages/terms/BrandGuidelinesPage'));
const PoliciesPage = lazy(() => import('./pages/terms/PoliciesPage'));

// Success/Cancel pages for Stripe
const PaymentSuccessPage = lazy(() => import('./pages/PaymentPages/PaymentSuccessPage'));
const PaymentCancelPage = lazy(() => import('./pages/PaymentPages/PaymentCancelPage'));

// Help/Support pages
const InformationPage = lazy(() => import('./pages/help/InformationPage'));
const SupportPage = lazy(() => import('./pages/help/SupportPage'));

function App() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* Public Routes with MainLayout */}
          <Route element={<MainLayout />}>
            {/* Public routes without Stripe */}
            <Route index element={<HomePage />} />
            <Route path="/login" element={<ClientSignupLoginPage />} />
            <Route path="/signup" element={<SignupPage />} />

            {/* Routes that need Stripe */}
            <Route
              path="/pricing"
              element={
                <StripeProvider>
                  <PricingPage />
                </StripeProvider>
              }
            />
            <Route
              path="/payment/success"
              element={
                <StripeProvider>
                  <PaymentSuccessPage />
                </StripeProvider>
              }
            />
            <Route
              path="/payment/cancel"
              element={
                <StripeProvider>
                  <PaymentCancelPage />
                </StripeProvider>
              }
            />

            {/* Company Routes */}
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/about-us/charter" element={<CharterPage />} />
            <Route path="/about-us/residency" element={<ResidencyPage />} />
            <Route path="/about-us/security" element={<SecurityPage />} />
            <Route path="/news" element={<NewsPage />} />

            {/* API Routes */}
            <Route path="/api/overview" element={<APIOverviewPage />} />
            <Route path="/api/documentation" element={<APIDocumentationPage />} />
            <Route
              path="/api/pricing"
              element={
                <StripeProvider>
                  <APIPricingPage />
                </StripeProvider>
              }
            />

            {/* Community Routes */}
            <Route path="/community/events" element={<EventsPage />} />
            <Route path="/community/forums" element={<ForumsPage />} />
            <Route path="/community/ambassadors" element={<AmbassadorsPage />} />

            {/* Developers Routes */}
            <Route path="/developers/sdks" element={<SDKsPage />} />
            <Route path="/developers/code-samples" element={<CodeSamplesPage />} />
            <Route path="/developers/api-integrations" element={<APIIntegrationsPage />} />

            {/* Products Routes */}
            <Route path="/products" element={<ProductsPage />} />
            <Route path="/products/solo" element={<SoloPage />} />
            <Route path="/products/solo/for-anyone" element={<SoloForAnyonePage />} />
            <Route path="/products/solo/for-enterprise" element={<SoloForEnterprisePage />} />

            {/* Research Routes */}
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/research/index" element={<ResearchIndexPage />} />
            <Route path="/research/autonomous-systems" element={<AutonomousSystemsPage />} />

            {/* Terms & Policies Routes */}
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/brand-guidelines" element={<BrandGuidelinesPage />} />
            <Route path="/policies" element={<PoliciesPage />} />

            {/* Help & Support Routes */}
            <Route path="/help" element={<InformationPage />} />
            <Route path="/help/support" element={<SupportPage />} />

            {/* 404 Page */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          {/* Protected Dashboard Routes with DashboardLayout */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <MessageProvider>
                  <WebSocketProvider>
                    <StripeProvider>
                      <DashboardLayout />
                    </StripeProvider>
                  </WebSocketProvider>
                </MessageProvider>
              </ProtectedRoute>
            }
          >
            <Route index element={<DashboardPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="integrations" element={<IntegrationsPage />} />
          </Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
