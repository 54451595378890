import React, { useContext, useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import styles from './DashboardLayout.module.css';
import { MessageContext } from '../../../../contexts/MessageContext';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import MessageGroup from '../../../../components/MessageGroup/MessageGroup';
import Sidebar from '../Sidebar/Sidebar';
import ChatBox from '../ChatBox/ChatBox';

const DashboardLayout = () => {
  const {
    conversations,
    getMessages,
    fetchMessages,
    activeConversationId,
    setActiveConversationId,
    addConversation,
    clearMessages,
    fetchConversations,
  } = useContext(MessageContext);
  
  const { isConnected } = useContext(WebSocketContext);
  
  const location = useLocation();
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const loadingTimeoutRef = useRef(null);
  const retryAttemptsRef = useRef(0);
  const MAX_RETRY_ATTEMPTS = 3;

  const handleConversationSelect = useCallback(async (conversationId) => {
    if (conversationId === activeConversationId) return;
  
    try {
      clearTimeout(loadingTimeoutRef.current);
      setError(null);
      setIsLoading(true);
  
      loadingTimeoutRef.current = setTimeout(() => {
        setError('Loading is taking longer than expected. Please try again.');
        setIsLoading(false);
      }, 10000);

      setActiveConversationId(conversationId);
      await fetchMessages(conversationId);
  
      retryAttemptsRef.current = 0;
    } catch (error) {
      console.error('Error switching conversations:', error);
      setError('Failed to load conversation. Please try again.');
      
      if (retryAttemptsRef.current < MAX_RETRY_ATTEMPTS) {
        retryAttemptsRef.current++;
        const delay = 1000 * Math.pow(2, retryAttemptsRef.current);
        console.log(`Retrying to switch conversation in ${delay}ms...`);
        await new Promise(resolve => setTimeout(resolve, delay));
        return handleConversationSelect(conversationId);
      }
    } finally {
      clearTimeout(loadingTimeoutRef.current);
      setIsLoading(false);
    }
  }, [activeConversationId, setActiveConversationId, fetchMessages]);

  const debouncedHandleConversationSelect = useMemo(
    () => debounce((conversationId) => {
      handleConversationSelect(conversationId).catch(console.error);
    }, 300),
    [handleConversationSelect]
  );
  
  const handleCreateConversation = async () => {
    try {
      setError(null);
      const newConversation = await addConversation();
      if (newConversation && newConversation._id) {
        await handleConversationSelect(newConversation._id);
      }
    } catch (error) {
      console.error('Error creating new conversation:', error);
      setError('Failed to create new conversation. Please try again.');
    }
  };

  const resetChat = useCallback(() => {
    clearMessages();
    // Clear any UI-related states
    setError(null);
    setIsLoading(false);
    // Reset retry attempts
    retryAttemptsRef.current = 0;
    // Clear any pending timeouts
    clearTimeout(loadingTimeoutRef.current);
    // Fetch fresh conversation list
    fetchConversations();
  }, [clearMessages, fetchConversations]);

  useEffect(() => {
    if (messagesEndRef.current && !isLoading) {
      const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      };
      
      const timeoutId = setTimeout(scrollToBottom, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [activeConversationId, conversations, isLoading]);

  useEffect(() => {
    return () => {
      debouncedHandleConversationSelect.cancel();
      clearTimeout(loadingTimeoutRef.current);
    };
  }, [debouncedHandleConversationSelect]);

  const renderMessagesContainer = () => {
    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner}>Loading conversation...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.errorContainer}>
          <div className={styles.errorMessage}>{error}</div>
          <button 
            className={styles.retryButton}
            onClick={() => {
              if (activeConversationId) {
                retryAttemptsRef.current = 0;
                handleConversationSelect(activeConversationId);
              }
            }}
          >
            Retry
          </button>
        </div>
      );
    }

    if (!activeConversationId) return null;

    const conversation = getMessages(activeConversationId);
    return <MessageGroup messages={conversation.messages} />;
  };

  return (
    <div className={styles.dashboardLayout}>
      <Sidebar 
        activeConversationId={activeConversationId} 
        onSelectConversation={debouncedHandleConversationSelect}
        onCreateConversation={handleCreateConversation}
        resetChat={resetChat}
      />
      <div className={styles.dashboardContentWrapper}>
        {(location.pathname === '/dashboard' || location.pathname === '/dashboard/') ? (
          <div className={styles.mainContent}>
            <div className={styles.messagesContainer}>
              {renderMessagesContainer()}
              <div ref={messagesEndRef} />
            </div>
            <ChatBox />
          </div>
        ) : (
          <Outlet />
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;