import React, { useContext, useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaCog, FaQuestionCircle, FaSignOutAlt, FaPen } from 'react-icons/fa';
import { AuthContext } from '../../../../contexts/AuthContext';
import styles from './Sidebar.module.css';
import SynchrotronAILogo from '../../../../assets/images/SynchrotronAI.svg';
import ConversationHistory from '../ConversationHistory/ConversationHistory';

const Sidebar = ({ activeConversationId, onSelectConversation, onCreateConversation, resetChat }) => {
  const { user, handleLogout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const userMenuRef = useRef(null);

  const toggleUserMenu = () => {
    setIsUserMenuOpen((prev) => !prev);
  };

  const handleNewChat = () => {
    resetChat();
    if (location.pathname !== '/dashboard') {
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleConversationSelect = (conversationId) => {
    if (conversationId !== activeConversationId) {
      if (location.pathname !== '/dashboard') {
        navigate('/dashboard');
      }
      onSelectConversation(conversationId);
    }
  };

  return (
    <div className={styles.sidebar}>
      <button 
        onClick={handleNewChat}
        className={styles.soloButton}
        aria-label="New Chat"
      >
        <img src={SynchrotronAILogo} alt="Logo" className={styles.smallLogo} />
        <span className={styles.soloText}>Solo</span>
        <FaPen className={styles.newChatIcon} />
      </button>

      <ConversationHistory 
        onSelectConversation={handleConversationSelect} 
        onCreateConversation={onCreateConversation}
      />

      {user && (
        <div className={styles.userSection} ref={userMenuRef}>
          <button
            className={styles.userInfo}
            onClick={toggleUserMenu}
            aria-haspopup="true"
            aria-expanded={isUserMenuOpen}
          >
            <span className={styles.userInitials}>
              {user.email.charAt(0).toUpperCase()}
            </span>
            <span className={styles.userEmail}>{user.email}</span>
            {isUserMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
          </button>
          <div
            className={`${styles.userMenu} ${
              isUserMenuOpen ? styles.userMenuOpen : ''
            }`}
            role="menu"
            aria-label="User menu"
          >
            <p className={styles.userPlan}>Professional Plan</p>
            <button
              onClick={() => navigate('/dashboard/settings')}
              className={styles.userMenuItem}
              role="menuitem"
            >
              <FaCog className={styles.menuIcon} />
              Settings
            </button>
            <button
              onClick={() => navigate('/help')}
              className={styles.userMenuItem}
              role="menuitem"
            >
              <FaQuestionCircle className={styles.menuIcon} />
              Help & Support
            </button>
            <button
              onClick={handleLogout}
              className={styles.userMenuItem}
              role="menuitem"
            >
              <FaSignOutAlt className={styles.menuIcon} />
              Log Out
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;