// src/pages/DashboardPage/Dashboard/ConversationHistory/ConversationHistory.js
import React, { useContext, useState, useRef, useEffect } from 'react';
import { MessageContext } from '../../../../contexts/MessageContext';
import styles from './ConversationHistory.module.css';
import { FaEllipsisV, FaArchive, FaTrash } from 'react-icons/fa';

const ConversationHistory = ({ onSelectConversation }) => { 
  const { conversations, activeConversationId, removeConversation, archiveConversation } = useContext(MessageContext);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpenId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleMenu = (conversationId, e) => {
    e.stopPropagation();
    setMenuOpenId(prevId => (prevId === conversationId ? null : conversationId));
  };

  const handleDelete = (e, conversationId) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this conversation? This action cannot be undone.')) {
      removeConversation(conversationId);
    }
    setMenuOpenId(null);
  };

  const handleArchive = (e, conversationId) => {
    e.stopPropagation();
    if (window.confirm('Do you want to archive this conversation?')) {
      archiveConversation(conversationId);
    }
    setMenuOpenId(null);
  };

  const renderConversationItem = (conversationId) => {
    const isActive = conversationId === activeConversationId;

    return (
      <div
        key={conversationId}
        className={`${styles.conversationItem} ${isActive ? styles.active : ''}`}
        onClick={() => onSelectConversation(conversationId)}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => { if (e.key === 'Enter') onSelectConversation(conversationId); }}
        aria-pressed={isActive}
      >
        <div className={styles.conversationInfo}>
          <span className={styles.conversationTitle}>
            Conversation {conversationId.slice(0, 8)}
          </span>
        </div>
        
        <div className={styles.menuContainer} ref={menuRef}>
          <button 
            className={styles.menuButton}
            onClick={(e) => toggleMenu(conversationId, e)}
            aria-haspopup="true"
            aria-expanded={menuOpenId === conversationId}
            aria-label={`Menu for conversation ${conversationId.slice(0, 8)}`}
          >
            <FaEllipsisV />
          </button>
          {menuOpenId === conversationId && (
            <div className={styles.menuDropdown}>
              <button 
                onClick={(e) => handleArchive(e, conversationId)} 
                className={styles.menuItem}
              >
                <FaArchive /> Archive
              </button>
              <button 
                onClick={(e) => handleDelete(e, conversationId)}
                className={styles.menuItem}
                style={{ color: '#ff4d4d' }}
              >
                <FaTrash /> Delete
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.conversationHistory}>
      <div className={styles.header}>
        <h2>Recent Conversations</h2>
      </div>
      <div className={styles.conversationsList}>
        {Object.keys(conversations).length > 0 ? (
          Object.keys(conversations).map((conversationId) => (
            renderConversationItem(conversationId)
          ))
        ) : (
          <p className={styles.noConversations}>No conversations available.</p>
        )}
      </div>
    </div>
  );
};

export default ConversationHistory;
