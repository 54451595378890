// TaskStatusDropdown.js

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChevronUp, ChevronDown, Clock, Loader2, CheckCircle2 } from 'lucide-react';
import styles from './TaskStatusDropdown.module.css';

const TaskStatusDropdown = ({ responses, finalResult }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current) {
        const toggleButton = dropdownRef.current.querySelector(`.${styles.dropdownSummary}`);
        // If clicking the toggle button, let the onClick handler handle it
        if (event.target === toggleButton || toggleButton?.contains(event.target)) {
          return;
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!Array.isArray(responses) || responses.length === 0) return null;
  
  // Debugging: Log responses and finalResult
  console.log('TaskStatusDropdown - received responses:', responses);
  console.log('TaskStatusDropdown - finalResult:', finalResult);

  // Determine if the task is complete using finalResult
  const isComplete = finalResult?.task_status === 'TASK_STATUS_COMPLETED';

  // Calculate processing time based on timestamps
  const userMessage = responses.find(msg => msg.type === 'MESSAGE_TYPE_USER_MESSAGE') || responses[0];
  const finalMessage = finalResult || responses[responses.length - 1];
  
  const startTime = new Date(userMessage.timestamp);
  const endTime = new Date(finalMessage.timestamp);
  const processingTime = !isNaN(startTime) && !isNaN(endTime) 
    ? Math.max(0, Math.round((endTime - startTime) / 1000)) // Prevent negative times
    : 0;

  // Debugging: Log processing time and completion status
  console.log('TaskStatusDropdown - isComplete:', isComplete);
  console.log('TaskStatusDropdown - processingTime:', processingTime);

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div className={styles.dropdownWrapper}>
        <button 
          className={styles.dropdownSummary}
          onClick={() => setIsOpen(!isOpen)}
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {isComplete ? (
            <>
              <CheckCircle2 className={styles.checkIcon} />
              <span>Worked on tasks for {processingTime} seconds</span>
            </>
          ) : (
            <>
              <Loader2 className={`${styles.clockIcon} ${styles.spin}`} />
              <span>Working on tasks...</span>
            </>
          )}
          {isOpen ? (
            <ChevronDown className={styles.dropdownIcon} />
          ) : (
            <ChevronUp className={styles.dropdownIcon} />
          )}
        </button>
      </div>

      {isOpen && (
        <div className={styles.processingSteps}>
          <div className={styles.timelineContainer}>
            {responses.map((response, index) => {
              const messageText = response?.content?.message_text || response?.text || '';
              const timestamp = response?.timestamp ? new Date(response.timestamp) : new Date();
              const formattedTime = !isNaN(timestamp) 
                ? timestamp.toLocaleTimeString([], { 
                    hour: '2-digit', 
                    minute: '2-digit' 
                  })
                : '';

              return (
                <div key={`${response.id || index}`} className={styles.processingStep}>
                  <div className={styles.stepContent}>
                    <div className={styles.stepText}>{messageText}</div>
                    <div className={styles.stepTimestamp}>{formattedTime}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

TaskStatusDropdown.propTypes = {
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.oneOf([
        'MESSAGE_TYPE_INIT_CONVERSATION',
        'MESSAGE_TYPE_INIT_CONVERSATION_ACK',
        'MESSAGE_TYPE_USER_MESSAGE',
        'MESSAGE_TYPE_RESPONSE_USER_MESSAGE',
        'MESSAGE_TYPE_FINAL_RESULT',
        'MESSAGE_TYPE_ERROR'
      ]).isRequired,
      content: PropTypes.shape({
        message_text: PropTypes.string
      }),
      text: PropTypes.string,
      timestamp: PropTypes.string.isRequired,
      parent_id: PropTypes.string
    })
  ).isRequired,
  finalResult: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf([
      'MESSAGE_TYPE_FINAL_RESULT'
    ]),
    content: PropTypes.shape({
      message_text: PropTypes.string,
      task_status: PropTypes.string
    }),
    text: PropTypes.string,
    timestamp: PropTypes.string.isRequired,
    parent_id: PropTypes.string
  }),
};

TaskStatusDropdown.defaultProps = {
  finalResult: null,
};

export default TaskStatusDropdown;