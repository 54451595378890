// src/contexts/MessageContext.js

import React, { createContext, useState, useCallback, useMemo, useEffect, useRef } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { MESSAGE_TYPES } from '../constants/messageTypes';

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [conversations, setConversations] = useState({});
  const [activeConversationId, setActiveConversationId] = useState(null);
  const [loading, setLoading] = useState(true);
  const messageQueue = useRef(new Map());
  const conversationLoadPromises = useRef(new Map());
  const conversationCache = useRef(new Map());

  // Message Queue Management
  const queueMessage = useCallback((conversationId, message) => {
    if (!messageQueue.current.has(conversationId)) {
      messageQueue.current.set(conversationId, []);
    }
    messageQueue.current.get(conversationId).push(message);
  }, []);

  const processMessageQueue = useCallback(async (conversationId) => {
    // Wait for any pending conversation load
    if (conversationLoadPromises.current.has(conversationId)) {
      await conversationLoadPromises.current.get(conversationId);
    }
  
    const messages = messageQueue.current.get(conversationId) || [];
    // Don't delete the queue until messages are processed
    
    if (messages.length > 0) {
      setConversations(prev => {
        const conversation = prev[conversationId] || { messages: [], userMessages: {} };
        const updatedMessages = [...conversation.messages];
        const updatedUserMessages = { ...conversation.userMessages };
  
        messages.forEach(message => {
          if (!updatedMessages.find(m => m.id === message.id)) {
            updatedMessages.push(message);
            if (message.parent_id) {
              if (!updatedUserMessages[message.parent_id]) {
                updatedUserMessages[message.parent_id] = [];
              }
              updatedUserMessages[message.parent_id].push(message);
            }
          }
        });
  
        const updatedConversation = {
          ...conversation,
          messages: updatedMessages,
          userMessages: updatedUserMessages,
          updatedAt: new Date().toISOString(),
        };
  
        // Update cache after successful processing
        conversationCache.current.set(conversationId, updatedConversation);
  
        return {
          ...prev,
          [conversationId]: updatedConversation,
        };
      });
  
      // Only delete queue after successful processing
      messageQueue.current.delete(conversationId);
    }
  }, []);

  const fetchConversations = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/conversations');
      const fetchedConversations = response.data.conversations;

      const conversationsMap = {};
      fetchedConversations.forEach((conv) => {
        conversationsMap[conv._id] = {
          messages: [],
          userMessages: {},
          archived: conv.archived,
          updatedAt: conv.updatedAt,
          isLocked: false,
        };
      });

      setConversations(conversationsMap);

      // Removed automatic selection of the first conversation
      // Users will have to select or create a conversation manually
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMessages = useCallback(async (conversationId) => {
    if (conversationLoadPromises.current.has(conversationId)) {
      return conversationLoadPromises.current.get(conversationId);
    }
  
    // Check cache first
    if (conversationCache.current.has(conversationId)) {
      const cachedConversation = conversationCache.current.get(conversationId);
      setConversations(prev => ({
        ...prev,
        [conversationId]: cachedConversation,
      }));
      processMessageQueue(conversationId);
      return cachedConversation.messages;
    }
  
    const promise = (async () => {
      try {
        const response = await axiosInstance.get(`/conversations/${conversationId}/messages`);
        let fetchedMessages = response.data.messages;
  
        // Map '_id' to 'id' and set 'timestamp'
        fetchedMessages = fetchedMessages.map(msg => ({
          ...msg,  // Keep original fields
          id: msg._id,
          text: msg.text,
          task_status: msg.task_status,  // Keep original task_status
          content: {
            message_text: msg.text,
            task_status: msg.task_status  // Use same task_status in content
          },
          timestamp: msg.createdAt || msg.timestamp
        }));
  
        // Log fetched messages
        console.log('Fetched Messages:', fetchedMessages);
  
        // Validate timestamps
        fetchedMessages.forEach(msg => {
          if (!msg.timestamp || isNaN(new Date(msg.timestamp).getTime())) {
            console.error('Invalid or missing timestamp for message:', msg);
          }
        });
  
        const conversation = {
          messages: fetchedMessages,
          userMessages: fetchedMessages.reduce((acc, msg) => {
            if (msg.parent_id) {
              if (!acc[msg.parent_id]) acc[msg.parent_id] = [];
              acc[msg.parent_id].push(msg);
            }
            return acc;
          }, {}),
          updatedAt: new Date().toISOString(),
          isLocked: false,
        };
  
        // Update cache
        conversationCache.current.set(conversationId, conversation);
  
        setConversations(prev => ({
          ...prev,
          [conversationId]: conversation,
        }));
  
        processMessageQueue(conversationId);
        return fetchedMessages;
      } catch (error) {
        console.error(`Error fetching messages for conversation ${conversationId}:`, error);
        throw error;
      } finally {
        conversationLoadPromises.current.delete(conversationId);
      }
    })();
  
    conversationLoadPromises.current.set(conversationId, promise);
    return promise;
  }, [processMessageQueue]);

  const clearMessages = useCallback(() => {
    setConversations({});
    setActiveConversationId(null);
    // Clear caches and queues
    messageQueue.current.clear();
    conversationLoadPromises.current.clear();
    conversationCache.current.clear();
  }, []);

  const addMessage = useCallback((conversationId, message) => {
    setConversations(prev => {
      const conversation = prev[conversationId];
      
      if (!conversation || conversationLoadPromises.current.has(conversationId)) {
        queueMessage(conversationId, message);
        return prev;
      }

      // Check if message already exists to prevent duplicates
      if (conversation.messages.some(m => m.id === message.id)) {
        return prev;
      }

      const updatedConversation = {
        ...conversation,
        messages: [...conversation.messages, message],
        userMessages: message.parent_id
          ? {
              ...conversation.userMessages,
              [message.parent_id]: [
                ...(conversation.userMessages[message.parent_id] || []),
                message,
              ],
            }
          : conversation.userMessages,
        updatedAt: new Date().toISOString(),
      };

      // Update cache
      conversationCache.current.set(conversationId, updatedConversation);

      return {
        ...prev,
        [conversationId]: updatedConversation,
      };
    });
  }, [queueMessage]);

  const cleanupConversation = useCallback((conversationId) => {
    messageQueue.current.delete(conversationId);
    conversationLoadPromises.current.delete(conversationId);
  }, []);

  const removeConversation = useCallback(async (conversationId) => {
    try {
      await axiosInstance.delete(`/conversations/${conversationId}`);
      
      setConversations(prev => {
        const updated = { ...prev };
        delete updated[conversationId];
        return updated;
      });

      // Clear from cache and queues
      conversationCache.current.delete(conversationId);
      cleanupConversation(conversationId);

      if (activeConversationId === conversationId) {
        const remainingConvs = Object.keys(conversations).filter(id => id !== conversationId);
        setActiveConversationId(remainingConvs.length > 0 ? remainingConvs[0] : null);
      }
    } catch (error) {
      console.error('Failed to delete conversation:', error);
      throw error;
    }
  }, [activeConversationId, conversations, cleanupConversation]);

  // Implement conversation creation
  const addConversation = useCallback(async () => {
    try {
      const response = await axiosInstance.post('/conversations');
      if (response.data.status === 'success') {
        const newConversation = response.data.conversation;
        setConversations(prev => ({
          ...prev,
          [newConversation._id]: {
            messages: [],
            userMessages: {},
            archived: newConversation.archived,
            updatedAt: newConversation.updatedAt,
            isLocked: false,
          }
        }));
        return newConversation;
      } else {
        throw new Error('Failed to create conversation.');
      }
    } catch (error) {
      console.error('Error creating conversation:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  useEffect(() => {
    if (activeConversationId) {
      fetchMessages(activeConversationId);
      return () => cleanupConversation(activeConversationId);
    }
  }, [activeConversationId, fetchMessages, cleanupConversation]);

  const contextValue = useMemo(() => ({
    conversations,
    addMessage,
    fetchMessages,
    fetchConversations,
    getMessages: (conversationId) => conversations[conversationId] || { messages: [], userMessages: {}, isLocked: false },
    removeConversation,
    addConversation,
    activeConversationId,
    setActiveConversationId,
    loading,
    clearMessages,
  }), [
    conversations,
    addMessage,
    fetchMessages,
    fetchConversations,
    removeConversation,
    addConversation,
    activeConversationId,
    loading,
    clearMessages,
  ]);

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );
};
